import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, HashRouter,useNavigate } from "react-router-dom";
import { lazy, Suspense } from 'react';
import { useEffect } from 'react';

const Home = lazy(() => import('./components/Home'));
const Register = lazy(() => import('./components/Register'));
const Checkout = lazy(() => import('./components/Checkout'));
const ShippingAddress = lazy(() => import('./components/ShippingAddress'));
const ProductDetail = lazy(() => import('./components/ProductDetail'));
const Cart = lazy(() => import('./components/Cart'));
const Login = lazy(() => import('./components/Login'));
const PaymentReturn = lazy(() => import('./components/PaymentReturn'));
const OrderDetails = lazy(() => import('./components/OrderDetails'));

function App() {

//   useEffect(() => {
//     const handleContextMenu = (event) => {
//         event.preventDefault(); // Prevent the default right-click menu
//     };

//     window.addEventListener('contextmenu', handleContextMenu);

//     // Cleanup event listener on component unmount
//     return () => {
//         window.removeEventListener('contextmenu', handleContextMenu);
//     };
// }, []);

  return (
    <HashRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="shippingaddress" element={<ShippingAddress />} />
          <Route path="productdetail/:id" element={<ProductDetail />} />
          <Route path="cart" element={<Cart />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="paymentreturn/:id" element={<PaymentReturn />} />
          <Route path="orderdetails" element={<OrderDetails />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;